define("backalley/pods/components/site-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgtg0EsZ",
    "block": "{\"symbols\":[\"HotLoadFaIconqamt89sp74\",\"HotLoadFaIconj3b24gdlqh\",\"&default\"],\"statements\":[[7,\"a\",true],[10,\"id\",\"site-toggle\"],[10,\"class\",\"site-toggle\"],[8],[0,\"\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"bars\"]]]],\"parameters\":[2]},null],[0,\"\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"times\"]]]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"site-title\"],[8],[0,\"Backalley\"],[9],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/site-header/template.hbs"
    }
  });

  _exports.default = _default;
});