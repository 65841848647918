define("backalley/pods/main/user-management/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7dOE0UAn",
    "block": "{\"symbols\":[\"HotLoadNotionHelpmwo9vso8ew\"],\"statements\":[[4,\"component\",[[28,\"hot-load\",[\"page-heading\",[23,0,[]],[24,[\"page-heading\"]],\"page-heading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"fa\"],[[23,0,[]],\"page-heading\",[24,[\"page-heading\"]],false,true,\"users\"]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[4,\"link-to\",null,[[\"route\"],[\"main.user_management\"]],{\"statements\":[[0,\"User management\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    Edit user\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"NotionHelp\",[23,0,[]],\"NotionHelp\",\"NotionHelp\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"NotionHelp\",\"NotionHelp\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@url\"],[\"https://www.notion.so/trouva/Onboarding-process-21818d7dedb14f4eb41e5f51ce1d68f0\"]]]],\"parameters\":[1]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[\"User management\"],null],false],[0,\"\\n\"],[1,[28,\"page-title\",[[24,[\"model\",\"user\",\"email\"]]],null],false],[0,\"\\n\\n\"],[1,[28,\"component\",[[28,\"hot-load\",[\"user-form\",[23,0,[]],[24,[\"user-form\"]],\"user-form\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\",\"user\",\"onSave\"],[[23,0,[]],\"user-form\",[24,[\"user-form\"]],false,true,[24,[\"model\",\"user\"]],[28,\"route-action\",[\"onSave\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/user-management/edit/template.hbs"
    }
  });

  _exports.default = _default;
});