define("backalley/pods/main/notifications/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MB604QJn",
    "block": "{\"symbols\":[\"HotLoadNotificationForm2jigexyjy4d\",\"HotLoadPageHeadingbc24iy5d4id\",\"@model\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PageHeading\",[23,0,[]],\"PageHeading\",\"PageHeading\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PageHeading\",\"PageHeading\",true,false]]]],null,{\"statements\":[[6,[23,2,[]],[],[[\"@fa\"],[\"bell\"]],{\"statements\":[[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"page-heading-title\"],[8],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\"],[\"main.notifications\"]],{\"statements\":[[0,\"\\n      Notifications centre\\n    \"]],\"parameters\":[]}],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n    \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"main.notifications.edit\",[23,3,[\"notification\",\"_id\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[23,3,[\"notification\",\"_id\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\\n\"],[1,[28,\"page-title\",[[23,3,[\"notification\",\"_id\"]]],null],false],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"NotificationForm\",[23,0,[]],\"NotificationForm\",\"NotificationForm\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"NotificationForm\",\"NotificationForm\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@notification\",\"@isEdit\"],[[23,3,[\"notification\"]],true]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/notifications/edit/template.hbs"
    }
  });

  _exports.default = _default;
});