define("backalley/pods/components/form-select-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Da6Ri3pa",
    "block": "{\"symbols\":[\"HotLoadFaIconlnglcci041m\",\"item\",\"index\"],\"statements\":[[7,\"select\",true],[10,\"class\",\"select\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"changed\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"prompt\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\",true],[10,\"disabled\",\"\"],[11,\"selected\",[28,\"not\",[[24,[\"currentValue\"]]],null]],[8],[1,[22,\"prompt\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],[[\"key\"],[\"@identity\"]],{\"statements\":[[0,\"    \"],[7,\"option\",true],[11,\"value\",[29,[[23,2,[\"value\"]]]]],[11,\"selected\",[28,\"eq\",[[23,2,[\"value\"]],[24,[\"currentValue\"]]],null]],[8],[1,[28,\"get\",[[23,2,[]],[24,[\"labelPath\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[9],[0,\"\\n\\n\"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\"],[\"angle-down\"]]]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-select-new/template.hbs"
    }
  });

  _exports.default = _default;
});