define("backalley/pods/main/statements/index-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ymm9fncY",
    "block": "{\"symbols\":[\"HotLoadFaIconyvzcx7q52hc\"],\"statements\":[[7,\"h1\",true],[10,\"style\",\"font-size: 1.5em; padding-top: 40vh; margin: 0 auto\"],[8],[0,\"\\n  \"],[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"FaIcon\",[23,0,[]],\"FaIcon\",\"FaIcon\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"FaIcon\",\"FaIcon\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[[12,\"class\",\"fa\"]],[[\"@icon\",\"@flip\"],[\"rocket\",\"horizontal\"]]]],\"parameters\":[1]},null],[0,\"\\n  Handcrafting statements. This might take a while!\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/main/statements/index-loading/template.hbs"
    }
  });

  _exports.default = _default;
});