define("backalley/pods/components/form-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9nNNUet",
    "block": "{\"symbols\":[\"HotLoadPowerSelecti317j2j63op\",\"option\"],\"statements\":[[4,\"let\",[[28,\"component\",[[28,\"hot-load\",[\"PowerSelect\",[23,0,[]],\"PowerSelect\",\"PowerSelect\"],null]],[[\"hotReloadCUSTOMhlContext\",\"hotReloadCUSTOMName\",\"hotReloadCUSTOMhlProperty\",\"hotReloadCUSTOMHasParams\",\"hotReloadCUSTOMHasHash\"],[[23,0,[]],\"PowerSelect\",\"PowerSelect\",true,false]]]],null,{\"statements\":[[6,[23,1,[]],[],[[\"@options\",\"@selected\",\"@onChange\",\"@placeholder\"],[[22,\"content\"],[22,\"_value\"],[28,\"action\",[[23,0,[]],\"onSelect\"],null],[22,\"placeholder\"]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"get\",[[23,2,[]],[24,[\"labelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[2]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "backalley/pods/components/form-select/template.hbs"
    }
  });

  _exports.default = _default;
});